import React from 'react';
import Obfuscate from "react-obfuscate";
import Mailchimp from 'react-mailchimp-form'
import { ReactComponent as Logo } from './images/oblek-logo.svg';
import SeaOfPulp from './images/sea-of-pulp.png';
import Hagioscope from './images/hagioscope.png';
import ScrollDown from './images/scroll-down.gif';
import './styles/App.scss';

function App() {
  return (
    <div className="App">
      <section className="intro">
        <Logo className="logo" />
        <img src={ScrollDown} alt=" " className="scroll-down" />
      </section>
      <section className="section-grid album hagioscope">
        <img src={Hagioscope} className="album-cover" alt="Paint Thinner - Hagioscope to the Heart" />
        <div className="album-content">
          <span className="catalog-no">Angle - 2 &bull; Oct. 18, 2019</span>
          <h2>PAINT THINNER</h2>
          <h3 className="album-title">Hagioscope to the Heart</h3>
          <p><em>Hagioscope to the Heart</em> is the second full-length album from Detroit's Paint Thinner. It is an expansion upon the band's noisy blend of psychedelic Sturm und Drang showcased on their debut – <em>The Sea of Pulp</em> – released in February of this year.</p>
          <p>But whereas the noise simmered below the gloom and murk on <em>The Sea of Pulp</em>, it’s present now in a more declaratory sense before either yielding to or consuming the melody entirely. The self-effacing desperation that permeated The Sea of Pulp while still present, is tempered by a sense of power. This isn’t anger, and this isn’t escapism. This is catharsis. </p>
          <p>Recorded and Mixed in the spring by Adam Cox at his own Hamtramck Sound Studios, <em>Hagioscope to the Heart</em> saw the band broadening its sound with additional instrumentation and due in no small part to the arrival of new drummer Jeff Else, who had handled production on their demo a few years prior.</p>
          <p><em>Hagioscope</em> is perhaps best understood as the sound of a band becoming, however unwillingly, comfortable with themselves.</p>
          <iframe title="Paint Thinner - Sue - Hagioscope to the Heart" style={{ border: 0, width: '100%', height: '42px' }} src="https://bandcamp.com/EmbeddedPlayer/album=926975141/size=small/bgcol=333333/linkcol=2ebd35/artwork=none/track=2990435732/transparent=true/" seamless><a href="http://paint-thinner.com/album/hagioscope-to-the-heart">Hagioscope to the Heart by Paint Thinner</a></iframe>
          <iframe title="Paint Thinner - Vision Quest - Hagioscope to the Heart" style={{ border: 0, width: '100%', height: '42px' }} src="https://bandcamp.com/EmbeddedPlayer/album=926975141/size=small/bgcol=333333/linkcol=2ebd35/artwork=none/track=86059278/transparent=true/" seamless><a href="http://paint-thinner.com/album/hagioscope-to-the-heart">Hagioscope to the Heart by Paint Thinner</a></iframe>
          <div className="controls">
            <a target="_blank" rel="noreferrer noopener" className="buy" href="https://paintthinner.bandcamp.com/album/hagioscope-to-the-heart">Buy LP / Digital</a>
          </div>
        </div>
      </section>
      <section className="section-grid album pulp">
        <img src={SeaOfPulp} className="album-cover" alt="Paint Thinner - The Sea of Pulp" />
        <div className="album-content">
          <span className="catalog-no">Angle - 1 &bull; Feb. 15, 2019</span>
          <h2>PAINT THINNER</h2>
          <h3 className="album-title">The Sea of Pulp</h3>
          <p>ŌBLĒK is proud to issue <em>The Sea of Pulp</em>, the debut album from Detroit's Paint Thinner, a band formed from the wreckage of the city's premier art-damaged heavies (Terrible Twos, Fontana, Frustrations, Human Eye).</p>
          <p>Invariably, when a rough-hewn Detroit band gets press, there is an impetus to draw comparisons to their forebears in the MC5 or The Stooges. If that's the case, then Paint Thinner would owe more to "We Will Fall" or the vamping of "Black To Comm" than they ever would to "Kick Out The Jams" or "Search & Destroy". Their music is what bubbles below the surface of the ordinary tensions we all face; their songs give a kind of context to these tensions, and in doing so they deftly convey emotion without oversimplification, supported by restless imagery and a downright melodic psychedelic dread.</p>
          <p>Recorded at Key Club Recording Company with Bill Skibbe (Protomartyr, The Kills, Jack White) and mixed by Daniel Tomczak, The Sea of Pulp is the attestation of a group unafraid to shed (though perhaps not entirely) the comfortable "loud-and-fast" trappings of their prior efforts in order to shape something headier and more dynamic. </p>
          <iframe title="Paint Thinner - Distortion - The Sea of Pulp" style={{ border: 0, width: '100%', height: '42px' }} src="https://bandcamp.com/EmbeddedPlayer/album=1956736364/size=small/bgcol=333333/linkcol=2ebd35/artwork=none/track=2919602295/transparent=true/" seamless><a href="http://paint-thinner.com/album/the-sea-of-pulp">The Sea of Pulp by Paint Thinner</a></iframe>
          <iframe title="Paint Thinner - Hidden Key - The Sea of Pulp" style={{ border: 0, width: '100%', height: '42px' }} src="https://bandcamp.com/EmbeddedPlayer/album=1956736364/size=small/bgcol=333333/linkcol=2ebd35/artwork=none/track=1783747019/transparent=true/" seamless><a href="http://paint-thinner.com/album/the-sea-of-pulp">The Sea of Pulp by Paint Thinner</a></iframe>
          <div className="controls">
            <a target="_blank" rel="noreferrer noopener" className="buy" href="https://paintthinner.bandcamp.com/album/the-sea-of-pulp">Buy LP / Digital</a>
          </div>
        </div>
      </section>
      <section className="section-grid contact">
        <div className="mailing-list">
          <h2>Mailing List</h2>
          <p>Sign up for irregular dispatches from ŌBLĒK, keeping you up to date on releases, tours, and other alarums and excursions from our small sound-world.</p>
          <Mailchimp
            action='https://oblek.us7.list-manage.com/subscribe/post?u=08f2ecc9f6aed17786194cd70&amp;id=8c54f867af'
            fields={[
              {
                name: 'EMAIL',
                placeholder: 'you@email.com',
                type: 'email',
                required: true
              }
            ]}
            messages={
              {
                sending: "Sending...",
                success: "Thank you for subscribing.",
                error: "An unexpected internal error has occurred.",
                empty: "Please enter a valid e-mail address.",
                duplicate: "Too many subscribe attempts for this email address.",
                button: "Subscribe"
              }
            }
          />
        </div>
        <div className="addresses">
          <h2>Contact Us</h2>
          <h3>For Wholesale/Order Inquiries:</h3>
          <Obfuscate email="orders@oblek.org" obfuscateChildren={false}>
            orders<span>@</span>oblek.org
          </Obfuscate>
          <h3>For Demo Submissions:</h3>
          <Obfuscate email="demos@oblek.org" obfuscateChildren={false}>
            demos<span>@</span>oblek.org
          </Obfuscate>
          <h3>For Anything Else:</h3>
          <Obfuscate email="info@oblek.org" obfuscateChildren={false}>
            info<span>@</span>oblek.org
          </Obfuscate>
        </div>


      </section>
      <footer><span>It's pronounced "oblique"</span></footer>
    </div>
  );
}

export default App;
